import React, { useCallback } from 'react';
import 'survey-core/defaultV2.min.css';
import { LayeredLightPanelless } from "survey-core/themes/layered-light-panelless";

import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

interface AppSurveyProps {
  survey: any,
  userId: number,
}

const AppSurvey = (props: AppSurveyProps) => {
  const { survey, userId } = props;
  const modelAttributes = { ...survey };
  const model = new Model(modelAttributes);
  model.applyTheme(LayeredLightPanelless);

  const surveyComplete = useCallback(async (survey) => {
    window.scrollTo(0, 0);
    const response = await saveSurveyResults(
      "survey_results",
      survey.data,
      userId,
    )
    const surveyResultId = response.survey_id;
    if (surveyResultId ) {
      window.location.assign(`survey_results/${surveyResultId }${isDebugSurvey() ? "?debug=true" : ""}`);
    } else {
      window.location.assign(`survey/${surveyResultId }${isDebugSurvey() ? "?debug=true" : ""}`);
    }
  }, []);

  model.onComplete.add(surveyComplete);

  return (
    <>
      <Survey model={model} />
    </>
  );
};

export default AppSurvey;

function isDebugSurvey() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const debug = urlParams.get('debug');
  return debug === "true";
}

async function saveSurveyResults(url, json, userId) {
  try {
    const result = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({ data: { data: json, user_id: userId } })
    })
    const data = await result.json();
    return data;
  } catch (error) {
    return error;
  }
}